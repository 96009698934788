import React from "react"
import ReactDOM from "react-dom/client"
import styles from "./index.css"

import App from "./App"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root"))

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
])

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
)
