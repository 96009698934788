import React, { useState, useEffect } from "react"
import { FaTimes } from "react-icons/fa"

// Components
import Table from "./components/table"
import LoadingSpinner from "./components/loadingSpinner"
import Examples from "./components/examples"
import ErrorMessage from "./components/error"
import * as Sentry from "@sentry/react"
import { Toaster } from "react-hot-toast"

import { notify } from "./Toast"
import { useDebouncedCallback } from "use-debounce"
import { useSearchParams } from "react-router-dom"

let api_endpoint = process.env.REACT_APP_API_ENDPOINT
    ? process.env.REACT_APP_API_ENDPOINT
    : "https://api.energychat.org"

console.debug("API Endpoint: " + api_endpoint)

if (!api_endpoint.endsWith("/")) {
    api_endpoint += "/"
}

const SearchButton = props => {
    const { value, onSearchChange, onClear } = props
    return (
        <div className="flex rounded-md shadow-sm w-full md:max-w-lg">
            <div className="relative flex flex-grow items-stretch focus-within:z-10  ">
                <input
                    type="text"
                    name="search"
                    id="search"
                    placeholder="Ask anything about Australian energy market"
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={value}
                    onChange={onSearchChange}
                />
            </div>
            <button
                type="button"
                className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={onClear}
            >
                <FaTimes />
            </button>
        </div>
    )
}
function App(props) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [query, setQuery] = useState("")
    const [results, setResult] = useState(undefined)
    const [statusCode, setStatusCode] = useState(0)
    const [errorMessage, setErrorMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [title, setTitle] = useState("")

    useEffect(() => {
        document.title = query || "energychat"
    }, [query])

    useEffect(() => {
        if (errorMessage !== "") {
            console.log(errorMessage)
            notify(errorMessage)
        }
    }, [errorMessage])

    const queryParameters = new URLSearchParams(window.location.search)
    const urlSearch = queryParameters.get("s")

    const handleSearchChange = event => {
        const { value } = event.target
        setQuery(value)
        setTitle("")
    }

    const handleClearSearch = () => {
        setQuery("")
        setResult(undefined)
        setSearchParams(undefined)
    }

    const fetchBackend = natural_language_query => {
        // Don't send a request if the query is empty!
        if (
            natural_language_query === undefined ||
            natural_language_query == null
        )
            return

        natural_language_query = natural_language_query.trim()

        if (!natural_language_query.length) return

        // Set the loading state
        setIsLoading(true)

        // Set the options for the fetch request
        const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: '{"query":"' + natural_language_query + '"}',
        }

        let responseOuter = null
        // Send the request
        fetch(api_endpoint + "v1/human_query", options)
            .then(response => response.json())
            .then(response => {
                // Set the loading state to false
                setIsLoading(false)

                // Handle errors
                if (!response || !response.results) {
                    setErrorMessage(
                        "Something went wrong. Please try again or try a different query",
                    )
                    return
                }

                // Set the state for SQL and Status Code
                console.log(response)

                // Filter out lat and long columns

                setResult(response)
            })
            .catch(err => {
                Sentry.setContext("queryContext", {
                    query: query,
                    ...responseOuter,
                })
                Sentry.captureException(err)
                setIsLoading(false)

                setStatusCode(500)
                setErrorMessage(err.message || err)
                setResult(undefined)
                console.error(err)
            })
    }

    const debouncedFetchBackend = useDebouncedCallback(query => {
        fetchBackend(query)
    }, 100)

    useEffect(() => {
        const queryFromURL = searchParams.get("s")
        if (queryFromURL !== query) {
            setQuery(urlSearch)
            debouncedFetchBackend(urlSearch)
        }
    }, [searchParams])

    const handleSearchClick = event => {
        setSearchParams(`?${new URLSearchParams({ s: query })}`)
        setTitle(query)
        fetchBackend(query)
    }

    return (
        <div className="App">
            <div className="overflow-hidden rounded-lg bg-white shadow md:h-screen">
                <div className="px-4 py-5 sm:px-6">
                    <h1
                        className="text-4xl font-bold mb-6"
                        onClick={() => {
                            window.location.assign("/")
                            handleClearSearch()
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        Energy Chat
                    </h1>
                    <div className="inline-flex gap-x-1.5 align-middle justify-center mb-3">
                        <p>Query Australian energy market data.</p>
                        <p>
                            Powered by{" "}
                            <a
                                href="https://opennem.org.au"
                                className="underline"
                            >
                                OpenNEM
                            </a>{" "}
                            and{" "}
                            <a href="https://openai.com" className="underline">
                                OpenAI
                            </a>
                            . Built by{" "}
                            <a
                                href="https://twitter.com/dir"
                                className="underline"
                            >
                                @dir
                            </a>
                        </p>
                    </div>
                    <Toaster />
                    <div>
                        <form
                            autoComplete={"off"}
                            className="mt-1 flex justify-center"
                            onSubmit={event => {
                                event.preventDefault()
                                handleSearchClick(event)
                            }}
                        >
                            <SearchButton
                                value={query}
                                onSearchChange={handleSearchChange}
                                onClear={handleClearSearch}
                            />
                            <button
                                type="submit"
                                className="text-white bg-blue-600 focus:ring-4 focus:ring-blue-300 focus:outline-none inline-flex items-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium shadow-sm hover:bg-blue-700 ml-3"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 h-full sm:p-6 flex flex-col md:flex-row md:pb-[200px]">
                    <div className="rounded-lg overflow-y-scroll max-h-[60vh] h-full md:h-full md:max-h-full bg-white shadow flex-grow-[0] w-full mr-8 mb-8">
                        <LoadingSpinner isLoading={isLoading} />
                        {!isLoading ? (
                            <Examples
                                setQuery={setQuery}
                                handleClick={fetchBackend}
                                setSearchParams={setSearchParams}
                                setTitle={setTitle}
                            />
                        ) : (
                            <> </>
                        )}
                        {!isLoading && results ? (
                            <div className="p-8">
                                <p class="my-2 font-medium"> {title} </p>
                                <Table results={results} />
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
