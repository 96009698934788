import React from "react"

const Examples = props => {
    const basic_example_queries = [
        "Current power price in New South Wales",
        "Current total demand on the NEM",
        "Total solar generation yesterday in Victoria",
        "How many solar farms are there in Australia and what is the total capacity?",
        "List all wind farms in Australia with capacity greater than 100MW",
        "South Australia renewable proportion every year last 10 years",
    ]

    return (
        <div className="p-4 border-b-2 pb-10">
            <p className={"my-2 font-medium pb-4"}>
                {" "}
                Give these examples a click{" "}
            </p>
            <div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {basic_example_queries.map(q => (
                        <div
                            key={q}
                            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                        >
                            <div className="min-w-0 flex-1">
                                <p
                                    className="focus:outline-none hover:cursor-pointer"
                                    onClick={() => {
                                        props.setQuery(q)
                                        props.handleClick(q)
                                        props.setSearchParams(
                                            `?${new URLSearchParams({ s: q })}`,
                                        )
                                        props.setTitle(q)
                                    }}
                                >
                                    <span
                                        className="absolute inset-0"
                                        aria-hidden="true"
                                    />
                                    <p className="text-sm font-medium text-gray-900">
                                        {q}
                                    </p>
                                </p>
                            </div>
                            <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                            </svg>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Examples
